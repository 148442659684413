
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElButton, ElForm, ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "customer-details-relation",
  props: ["citizenRelation", "orgRelation", "loancode", "customercode"],
  emits: ["reloadLoanDetails"],
  data() {
    return {
      loadButtonCitizen: "off",
      loadButtonOrg: "off",
      modalCitizenForm: {
        isForeign: "1",
        lastname: "",
        subjectname: "",
        isfinancial: "1",
        citizenrelation: "",
        registerno: ""
      },
      modalCitizenFormRule: {
        lastname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        subjectname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        citizenrelation: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: [
          {
            required: true,
            message: "Хоосон байна.",
            trigger: "blur"
          },
          {
            len: 10,
            message: "Буруу байна.",
            trigger: "blur"
          }
        ]
      },
      modalOrgForm: {
        isForeign: "1",
        subjectname: "",
        stateregisterno: "",
        isfinancial: "1",
        orgrelation: "",
        registerno: ""
      },
      modalOrgFormRule: {
        subjectname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        orgrelation: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        }
      }
    };
  },
  setup() {
    const formRefCitizen = ref<InstanceType<typeof ElForm>>();
    const formRefOrg = ref<InstanceType<typeof ElForm>>();
    const submitRefCitizen = ref<InstanceType<typeof ElButton>>();
    const submitRefOrg = ref<InstanceType<typeof ElButton>>();

    const formSubmitCitizen = () => {
      formRefCitizen.value?.validate(async valid => {
        if (valid) submitRefCitizen.value?.$el.click();
        else return false;
      });
    };

    const formSubmitOrg = () => {
      formRefOrg.value?.validate(async valid => {
        if (valid) submitRefOrg.value?.$el.click();
        else return false;
      });
    };

    const clearFieldCitizen = () => {
      formRefCitizen.value?.resetFields();
    };

    const clearFieldOrg = () => {
      formRefOrg.value?.resetFields();
    };

    return {
      formRefCitizen,
      submitRefCitizen,
      formSubmitCitizen,
      clearFieldCitizen,
      formRefOrg,
      submitRefOrg,
      formSubmitOrg,
      clearFieldOrg
    };
  },
  methods: {
    async submitCitizen() {
      this.loadButtonCitizen = "on";
      const params = {
        type: "citizen",
        loancode: this.loancode,
        customercode: this.customercode,
        islocal: this.modalCitizenForm.isForeign,
        lastname: this.modalCitizenForm.lastname,
        subjectname: this.modalCitizenForm.subjectname,
        isfinancial: this.modalCitizenForm.isfinancial,
        citizenrelation: this.modalCitizenForm.citizenrelation,
        registerno: this.modalCitizenForm.registerno
      };
      await ApiService.post("customerdetails", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              (document.getElementById(
                "modalBackButtonRelCitizen"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButtonCitizen = "off";
        });
    },
    async submitOrg() {
      this.loadButtonOrg = "on";
      const params = {
        type: "org",
        loancode: this.loancode,
        customercode: this.customercode,
        islocal: this.modalOrgForm.isForeign,
        subjectname: this.modalOrgForm.subjectname,
        isfinancial: this.modalOrgForm.isfinancial,
        orgrelation: this.modalOrgForm.orgrelation,
        stateregisterno: this.modalOrgForm.stateregisterno,
        registerno: this.modalOrgForm.registerno
      };
      await ApiService.post("customerdetails", { params })
        .then(res => {
          const results = res.data;
          if (results.code === 0) {
            Swal.fire({
              text: results.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            });
          } else {
            Swal.fire({
              text: results.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "За",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(() => {
              this.$emit("reloadLoanDetails");
              (document.getElementById(
                "modalBackButtonRelCitizen"
              ) as HTMLButtonElement).click();
            });
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadButtonCitizen = "off";
        });
    }
  }
});
