
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CustomerDetailsRelation from "@/components/modals/customerDetailsRelationModal.vue";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "CustomerDetails",
  components: { CustomerDetailsRelation },

  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээлдэгчийн дэлгэрэнгүй");
    });
  },
  mounted() {
    this.getLoanDetails();
  },
  data() {
    const idArr = this.b64DecodeUnicode(this.$route.params.id)
      .toString()
      .split("@");
    const loancode = idArr[0];
    const customercode = idArr[2];
    const version = process.env.VUE_APP_LOAN_FORM_EL_ALERT_DESC;

    return {
      version,
      dataLoadingCitizen: false,
      dataLoadingOrg: false,
      citizenRelation: [],
      orgRelation: [],
      customerRelationOrg: [],
      customerRelationCitizen: [],
      loancode,
      customercode
    };
  },
  methods: {
    async getLoanDetails() {
      this.dataLoadingOrg = true;
      this.dataLoadingCitizen = true;
      await ApiService.get("customerdetails", `?loancode=${this.loancode}`)
        .then(res => {
          this.loancode = res.data.loancode;
          this.citizenRelation = res.data.citizenrelation.map(row => ({
            description: row.description,
            itemid: row.itemid,
            name1: row.name1,
            name2: row.name2
          }));
          this.orgRelation = res.data.orgrelation.map(row => ({
            description: row.description,
            itemid: row.itemid,
            name1: row.name1,
            name2: row.name2
          }));
          this.customerRelationCitizen = res.data.customerRelationCitizen.map(
            row => ({
              customercode: row.customercode,
              registerno: row.registerno,
              lastname: row.lastname,
              subjectname: row.subjectname,
              stateregisterno: row.stateregisterno,
              islocal: row.islocal,
              isfinancial: row.isfinancial,
              citizenorgrelation: row.citizenorgrelation,
              loancode: row.loancode
            })
          );
          this.customerRelationOrg = res.data.customerRelationOrg.map(row => ({
            customercode: row.customercode,
            registerno: row.registerno,
            lastname: row.lastname,
            subjectname: row.subjectname,
            stateregisterno: row.stateregisterno,
            islocal: row.islocal,
            isfinancial: row.isfinancial,
            citizenorgrelation: row.citizenorgrelation,
            loancode: row.loancode
          }));
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoadingOrg = false;
          this.dataLoadingCitizen = false;
        });
    }
  }
});
